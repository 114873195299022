import React, { Component } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import i18next from "i18next";
import { stripHtml } from "utils";

export class OpenEndedFeedbacksCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedFeedbacks: {},
    };
  }

  componentDidMount() {
    window.addEventListener("beforeprint", this.handlePrint);
    window.addEventListener("afterprint", this.resetPrintState);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeprint", this.handlePrint);
    window.removeEventListener("afterprint", this.resetPrintState);
  }

  handlePrint = () => {
    const { openEndedFeedbacks_1 = [], openEndedFeedbacks_2 = [] } = this.props;
    this.setState(() => {
      const expandedFeedbacks = {};
      openEndedFeedbacks_1.forEach((_, index) => {
        expandedFeedbacks[index] = true;
      });
      openEndedFeedbacks_2.forEach((_, index) => {
        expandedFeedbacks[index + openEndedFeedbacks_1.length] = true;
      });
      return { expandedFeedbacks };
    });
  };

  resetPrintState = () => {
    this.setState({ expandedFeedbacks: {} });
  };

  toggleFeedback = (index) => {
    this.setState((prevState) => ({
      expandedFeedbacks: {
        ...prevState.expandedFeedbacks,
        [index]: !prevState.expandedFeedbacks[index],
      },
    }));
  };

  getVisibleText = (text, isExpanded) => {
    if (isExpanded || !text) return text;
    return text.length > 150 ? text.slice(0, 150) + "..." : text;
  };

  render() {
    const { t } = i18next;
    const {
      openEndedFeedbacks_1 = [],
      openEndedFeedbacks_2 = [],
    } = this.props;
    const { expandedFeedbacks } = this.state;
    const { getVisibleText } = this;

    return (
      <>
        {/* Feedback 1 */}
        {Array.isArray(openEndedFeedbacks_1) &&
          openEndedFeedbacks_1.length > 0 && (
            <Card className="card-element report-card feedback">
              <CardBody className="open-ended-feedback">
                <div className="feedback-section feedback-1">
                  <CardTitle tag="h5">{t("report.feedback-1.title")}</CardTitle>
                  <ol>
                    {openEndedFeedbacks_1.map((feedback, index) => (
                      <li key={`feedback1-${index}`}>
                        {getVisibleText(stripHtml(feedback), expandedFeedbacks[index])}
                        {stripHtml(feedback).length > 150 && (
                          <span
                            className="toggle-feedback"
                            onClick={() =>
                              !window.matchMedia("print").matches &&
                              this.toggleFeedback(index)
                            }
                          >
                            {expandedFeedbacks[index]
                              ? t("report.showLess")
                              : t("report.showMore")}
                          </span>
                        )}
                      </li>
                    ))}
                  </ol>
                </div>
              </CardBody>
            </Card>
          )}

        {/* Feedback 2 */}
        {Array.isArray(openEndedFeedbacks_2) &&
          openEndedFeedbacks_2.length > 0 && (
            <Card className="card-element report-card feedback">
              <CardBody className="open-ended-feedback">
                <div className="feedback-section feedback-2">
                  <CardTitle tag="h5">{t("report.feedback-2.title")}</CardTitle>
                  <ol>
                    {openEndedFeedbacks_2.map((feedback, index) => (
                      <li key={`feedback2-${index}`}>
                        {getVisibleText(
                          stripHtml(feedback),
                          expandedFeedbacks[index + openEndedFeedbacks_1.length]
                        )}
                        {stripHtml(feedback).length > 150 && (
                          <span
                            className="toggle-feedback"
                            onClick={() =>
                              !window.matchMedia("print").matches &&
                              this.toggleFeedback(
                                index + openEndedFeedbacks_1.length
                              )
                            }
                          >
                            {expandedFeedbacks[index + openEndedFeedbacks_1.length]
                              ? t("report.showLess")
                              : t("report.showMore")}
                          </span>
                        )}
                      </li>
                    ))}
                  </ol>
                </div>
              </CardBody>
            </Card>
          )}
      </>
    );
  }
}

export default OpenEndedFeedbacksCard;
