import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Container, Card, CardBody, CardSubtitle } from 'reactstrap';
import {Header} from 'components/Layout/Header'
import RaterInvitation from 'components/RaterInvitation';
//import {sendRatersInvitation} from 'api/index';
import {ATRAIN_ROUTES, getRoute} from 'routes'
import AnimatedPopup from 'components/AnimatedPopup';
import i18next from "i18next";
import invitationService from 'api/invitationService';

export class InviteRaterPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded:true,
      users:[],
      isDialogVisible:false
    }
  }

  componentDidMount(){
  }

  handleSendRatersInvitation(users, message) {
      //let history = this.props.history;
      let assessmentId = this.props.match.params.assessmentId

      let data = {
        //assessmentId:assessmentId,
        raters:users,
        message
      }

      invitationService.sendRatersInvitation(assessmentId,data).then((resp) => {
            this.setState({
              isDialogVisible: true
            })

            //history.push(getRoute(ATRAIN_ROUTES.INVITE_SPONSOR,{assessmentId}));
            //history.push("/invite-sponsor/" + assessmentId)
      }).catch(err =>{
        alert(i18next.t('networkError'))
        console.log('err', err)
      })
  }

  render() {
    const {loaded} = this.state
    const { t } = i18next
    //let assessmentId = this.props.match.params.assessmentId
//bgcolor="#3397D9"
    return (

      <>
      {loaded && <div className={'invite-rater'}>

        <Header
          className={'blocker'}
          closeLink={getRoute(ATRAIN_ROUTES.REPORT,{assessmentId:this.props.match.params.assessmentId})}
          >

        </Header>

        <Card className={'card-element rounded-big'} >
          <CardBody className=''>
          <div className="inviteRaterSuggestIntro" dangerouslySetInnerHTML={{ __html: t('assessment.invite.suggestintro_text') }}></div>
            <RaterInvitation onSend={this.handleSendRatersInvitation.bind(this)} assessmentId={this.props.match.params.assessmentId}/>

          </CardBody>
        </Card>

        <Container className='invite-rater-inner'>

        </Container>

        <AnimatedPopup visible={this.state.isDialogVisible}>
          <CardSubtitle dangerouslySetInnerHTML={{ __html: t('assessment.invite.gotodashboard_text') }}></CardSubtitle>
          <Link to={getRoute(ATRAIN_ROUTES.DASHBOARD)}><button className="btn btn-sm w-100 btn-primary mb-3" dangerouslySetInnerHTML={{ __html: t('assessment.invite.gotodashboard_btn') }}></button></Link>
        </AnimatedPopup>

      </div>

      }
      </>
    );
  }
}




/*
const mapStateToProps = (state) => {
  return ({
    mainStore: state.main
})};

const mapDispatchToProps = {setUser};

const DashboardPage = connect(mapStateToProps, mapDispatchToProps)(DashboardPageInner);
*/
