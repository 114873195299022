import React, { Component } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { Tooltip } from "reactstrap";
import Slider from "./Slider";
import { getItems } from "api/index";
//import { textChangeRangeIsUnchanged } from 'typescript';
import { stripHtml } from "utils";
import i18next from "i18next";
import { IconUserTagOutline, IconUserVoteOutline } from 'components/icons'

export class CardElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentCount: 0,
    };

    this.incrementCounter = this.incrementCounter.bind(this);
  }

  // Coputed props
  get enablers() {
    return this.props.competency.enablers; //behaviorMarker.filter(item => item.isPositiveMarker)
  }

  get blockers() {
    return this.props.competency.blockers; //behaviorMarker.filter(item => !item.isPositiveMarker)
  }

  componentDidMount() {
    console.log(getItems);
  }

  componentWillUnmount() {
  }

  incrementCounter() {
    this.setState({
      currentCount: this.state.currentCount + 1,
    });
  }

  toggleExpand() {
    this.setState({
      isExpanded: !this.state.isExpanded,
    });
  }

  toggleTooltip() {
    this.setState((prevState) => ({
      tooltipOpen: !prevState.tooltipOpen,
    }));
  }

  render() {
    const { t } = i18next;

    return (
      <>
        <Card className="card-element report-card">
          <CardBody>
            <div
              className="cardToggler custom_flex"
              onClick={this.toggleExpand.bind(this)}>
              <div className="report-legend inside-element">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}>
                  <IconUserTagOutline nopadding={true} />{" "}
                  <div>{t("report.legend_myself")}</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}>
                  <IconUserVoteOutline nopadding={true} />{" "}
                  <div>{t("report.legend_they")}</div>
                </div>
              </div>
              <CardTitle tag="h5">
                {stripHtml(this.props.competency.competencyName)}
                <div className="compentency-description">
                  {stripHtml(this.props.competency.competencyDescription)}
                </div>
              </CardTitle>
              <div className="h_40px">
                <svg
                  width="26px"
                  height="26px"
                  viewBox="0 0 26 26"
                  id={`infoIcon-${this.props.uniqueId}`}
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12 3.53846C7.32682 3.53846 3.53846 7.32682 3.53846 12C3.53846 16.6732 7.32682 20.4615 12 20.4615C16.6732 20.4615 20.4615 16.6732 20.4615 12C20.4615 7.32682 16.6732 3.53846 12 3.53846ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
                      fill="#000000"></path>{" "}
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12 16.359C12.4248 16.359 12.7692 16.0146 12.7692 15.5897V11.4872C12.7692 11.0623 12.4248 10.7179 12 10.7179C11.5752 10.7179 11.2308 11.0623 11.2308 11.4872V15.5897C11.2308 16.0146 11.5752 16.359 12 16.359Z"
                      fill="#000000"></path>{" "}
                    <path
                      d="M13.0256 8.41026C13.0256 7.84381 12.5664 7.38462 12 7.38462C11.4336 7.38462 10.9744 7.84381 10.9744 8.41026C10.9744 8.9767 11.4336 9.4359 12 9.4359C12.5664 9.4359 13.0256 8.9767 13.0256 8.41026Z"
                      fill="#000000"></path>{" "}
                  </g>
                </svg>
                <Tooltip
                  placement="top"
                  isOpen={this.state.tooltipOpen}
                  target={`infoIcon-${this.props.uniqueId}`}
                  toggle={this.toggleTooltip}>
                  {stripHtml(this.props.competency.competencyDescription)}
                </Tooltip>
              </div>
            </div>

            <div className="item-list-labels">
              {
                <b
                  className={
                    "collapsable text-pink" +
                    (this.state.isExpanded ? " collapsed" : "")
                  }>
                  {stripHtml(
                    this.props.competency.blockers.markersAverage.title
                  )}
                </b>
              }
              <b className="float-right text-blue">
                {stripHtml(this.props.competency.enablers.markersAverage.title)}
              </b>
            </div>
            <div className="items-list enablers-list">
              <Slider
                amount={
                  this.props.competency.enablers.markersAverage
                    .ratersAverageScore
                }
                amount2={
                  this.props.competency.enablers.markersAverage.personScore
                }
                bigger={true}
                color="#3397D9"
              />
              <div
                className={
                  "card-detail " + (this.state.isExpanded ? "expanded" : "")
                }>
                {this.props.competency.enablers.markers.map((i, idx) => (
                  <Slider
                    key={idx.toString()}
                    title={stripHtml(i.title)}
                    amount={i.ratersAverageScore}
                    amount2={i.personScore}
                    color="#3397D9"
                  />
                ))}
              </div>
            </div>

            {
              <b
                className={
                  "collapsable text-pink d-block" +
                  (!this.state.isExpanded ? " collapsed" : "")
                }>
                {stripHtml(this.props.competency.blockers.markersAverage.title)}
              </b>
            }
            <div className="items-list blocker-list">
              <Slider
                amount={
                  this.props.competency.blockers.markersAverage
                    .ratersAverageScore
                }
                amount2={
                  this.props.competency.blockers.markersAverage.personScore
                }
                bigger={true}
                toLeft={true}
                color="#D71C8B"
              />
              <div
                className={
                  "card-detail " + (this.state.isExpanded ? "expanded" : "")
                }>
                {this.props.competency.blockers.markers.map((i, idx) => (
                  <Slider
                    key={idx.toString()}
                    title={stripHtml(i.title)}
                    amount={i.ratersAverageScore}
                    amount2={i.personScore}
                    toLeft={true}
                    color="#D71C8B"
                  />
                ))}
              </div>
            </div>

            <div
              className={
                "card-expand-toggler" +
                (!this.state.isExpanded ? " collapsed" : "")
              }
              onClick={this.toggleExpand.bind(this)}></div>
          </CardBody>
        </Card>
      </>
    );
  }
}
