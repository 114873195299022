import React, { Component } from 'react';
import {IconArrowSquareLeft} from 'components/icons'
import i18next from "i18next";
import { Link} from 'react-router-dom';

export class Header extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currentCount: 0
    }

  }

  // Coputed props
  /*get enablers() {
    return this.props.competency.behaviorMarker.filter(item => item.isPositiveMarker)
  }*/


  componentDidMount(){

  }

  incrementCounter() {
    this.setState({
      currentCount: this.state.currentCount + 1
    });
  }

  toggleExpand() {
    this.setState({
      isExpanded: !this.state.isExpanded
    });
  }

  render() {
    const { t } = i18next
    const { backcloseLink = true, } = this.props;
    return (
        <header className={'top-header ' + (this.props.className || '' )} style={(this.props.bgcolor) ? {backgroundColor: this.props.bgcolor} : null}>
          {backcloseLink && (
            <div className="header-icons-wrapper">
              <Link to={this.props.closeLink}>
                <IconArrowSquareLeft/>
                <span className="txt">{t('btnBack')}</span>
              </Link>

              {/*<Link to={this.props.closeLink}>
                <IconCloseSquare/>
                <span className="txt">{this.props.closeText || t('header.btnCloseLabel')}</span>
              </!Link>*/}
            </div>
          )}

            <h1>{this.props.title}</h1>
            <h2>{this.props.subtitle && this.props.subtitle}</h2>

            {this.props.children && this.props.children}

        </header>
    );
  }
}
