import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  Col,
  CardSubtitle,
} from "reactstrap";
import { IconCloseSquare, IconInfoCircle, IconHand } from "components/icons";
import i18next from "i18next";
import axios from "axios";
import { Header } from "components/Layout/Header";
import { ATRAIN_ROUTES, getRoute } from "routes";
import AnimatedPopup from "components/AnimatedPopup";

const RaterFeedbackPage = (props) => {
  const { t } = i18next;
  const { assessmentId } = useParams();
  const history = useHistory();

  const [strengths, setStrengths] = useState("");
  const [developmentAreas, setDevelopmentAreas] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isLeaveAssessmentDialogVisible, setIsLeaveAssessmentDialogVisible] =
    useState(false);
  const [feedbackReceiverData, setFeedbackReceiverData] = useState(null);
  const [isFeedbackState, setIsFeedbackState] = useState(false);

  const toggleLeaveAssessmentPopup = () => {
    setIsLeaveAssessmentDialogVisible((prev) => !prev);
  };

  const leaveAssessment = () => {
    // Naviga verso la dashboard
    history.push(getRoute(ATRAIN_ROUTES.DASHBOARD));
    toggleLeaveAssessmentPopup(); // Chiudi il popup dopo la navigazione
  };

  const fetchReportData = async (assessmentId) => {
    try {
      const response = await axios.get(
        `/api/AssessmentSession/${assessmentId}`
      );
      const { OpenEndedFeedbacks_1, OpenEndedFeedbacks_2, feedbackReceiver,
        isFeedback,
      } = response.data;
      return {
        OpenEndedFeedbacks_1,
        OpenEndedFeedbacks_2,
        feedbackReceiver,
        isFeedback,
      };
    } catch (error) {
      console.error("Error fetching report data:", error);
      throw new Error("Unable to fetch report data.");
    }
  };


  const saveFeedback = async (data) => {
    const apiUrl = `/api/AssessmentSession/OpenEndedFeedback/${data.assessmentId}`;
    const headers = {
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.patch(apiUrl, {
        Feedback_1: data.strengths,
        Feedback_2: data.developmentAreas,
      }, { headers });
      return response;
    } catch (error) {
      console.error("Error saving feedback:", error);
      throw new Error(error.response?.message || "Failed to save feedback.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    if (!strengths.trim() || !developmentAreas.trim()) {
      setError(t("assessment.feedback.validationError"));
      setLoading(false);
      return;
    }

    const data = {
      assessmentId,
      strengths,
      developmentAreas,
    };

    try {
      const response = await saveFeedback(data);
      console.log("Feedback saved:", response);

      if (response.status >= 200 && response.status < 300) {
        history.push(getRoute(ATRAIN_ROUTES.DASHBOARD));
      } else {
        setError(response.data.message || "An error occurred.");
      }
    } catch (err) {
      console.error("Save error:", err);
      setError("Failed to save feedback. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const loadReportData = async () => {
      try {
        const {
          OpenEndedFeedbacks_1,
          OpenEndedFeedbacks_2,
          feedbackReceiver,
          isFeedback,
        } = await fetchReportData(assessmentId);

        setStrengths(OpenEndedFeedbacks_1?.join("\n") || "");
        setDevelopmentAreas(OpenEndedFeedbacks_2?.join("\n") || "");
        setFeedbackReceiverData(feedbackReceiver);
        setIsFeedbackState(isFeedback);
      } catch (error) {
        console.error("Error loading report data:", error);
        setError(t("assessment.feedback.loadError"));
      }
    };

    loadReportData();
  }, [assessmentId]);

  const tokens = {
    rater: {
      name: feedbackReceiverData?.firstName || "",
      surname: feedbackReceiverData?.lastName || "",
    },
    focus: {
      name: feedbackReceiverData?.firstName || "",
      surname: feedbackReceiverData?.lastName || "",
    },
  };

  // E per il context:
  const ownerCtx = isFeedbackState ? "rater" : "owner";
  console.log(tokens);

  return (
    <div className="rater-feedback">
      <Header
        backcloseLink={false}
        closeLink={getRoute(ATRAIN_ROUTES.REPORT, {
          assessmentId: assessmentId,
        })}
      />

      <div className="header-icons-wrapper">
        <IconCloseSquare onClick={toggleLeaveAssessmentPopup} />
      </div>

      {/* Leave Assessment Popup */}
      <AnimatedPopup
        visible={isLeaveAssessmentDialogVisible}
        showCloseButton={false}
      >
        <CardSubtitle>{t("assessment.leaveAssessment.popup.title")}</CardSubtitle>
        <div>
          <button
            onClick={leaveAssessment}
            className="minw-25 btn btn-sm btn-outline-primary mr-2"
          >
            {t("assessment.leaveAssessment.popup.btnConfirm")}
          </button>
          <button
            onClick={toggleLeaveAssessmentPopup}
            className="minw-25 btn btn-sm btn-primary"
          >
            {t("assessment.leaveAssessment.popup.btnCancel")}
          </button>
        </div>
      </AnimatedPopup>

      <Container>
        <Row>
          <Col xs={12} md={12} className="mx-auto">
            <Card className="card-element rounded-big">
              <CardBody>
                <h3
                  dangerouslySetInnerHTML={{
                    __html: t("assessment.feedback.title"),
                  }}
                ></h3>
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Label
                      dangerouslySetInnerHTML={{
                        __html: t("assessment.feedback.strengthsPrompt", { context: ownerCtx, ...tokens }),
                      }}
                    />
                    <Input
                      type="textarea"
                      value={strengths}
                      onChange={(e) => setStrengths(e.target.value)}
                      placeholder={t(
                        "assessment.feedback.strengthsPlaceholder", { context: ownerCtx, ...tokens }
                      )}
                      disabled={loading}
                      rows={6}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label
                      dangerouslySetInnerHTML={{
                        __html: t("assessment.feedback.devAreasPrompt", { context: ownerCtx, ...tokens })
                      }}
                    />
                    <Input
                      type="textarea"
                      value={developmentAreas}
                      onChange={(e) => setDevelopmentAreas(e.target.value)}
                      placeholder={t(
                        "assessment.feedback.devAreasPlaceholder", { context: ownerCtx, ...tokens }
                      )}
                      disabled={loading}
                      rows={6}
                    />
                  </FormGroup>
                  {error && (
                    <p className="text-danger">{error}</p>
                  )}
                  <Button
                    type="submit"
                    color="primary"
                    block
                    dangerouslySetInnerHTML={{
                      __html: loading
                        ? t("assessment.feedback.loadingMessage")
                        : t("assessment.feedback.submitBtn"),
                    }}
                    disabled={loading}
                  />
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default RaterFeedbackPage;
