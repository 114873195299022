import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { stripHtml } from "utils";
import i18next from "i18next";

// Componente AccordionTitle
class AccordionTitle extends Component {
  render() {
    const { title, isOpen, toggleAccordion } = this.props;

    return (
      <div className="accordion-title" onClick={toggleAccordion}>
        {title}
        <div className="svg_custom">
          <div className="chevron-down">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="13"
              fill="#ffff"
              className="bi bi-chevron-down"
              viewBox="0 0 16 16"
              style={{
                transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
                transition: "transform 0.3s ease",
              }}>
              <path
                fillRule="evenodd"
                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
              />
            </svg>
          </div>
        </div>
      </div>
    );
  }
}

// Componente AccordionContent
class AccordionContent extends Component {
  constructor(props) {
    super(props);
    this.contentRef = React.createRef(); // Per calcolare l'altezza del contenuto
    this.state = {
      height: 0, // Altezza dinamica
    };
  }

  componentDidUpdate(prevProps) {
    // Aggiorna l'altezza quando `isOpen` cambia
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({
        height: this.props.isOpen ? this.contentRef.current.scrollHeight : 0,
      });
    }
  }

  render() {
    const { data, isOpen } = this.props;
    const { height } = this.state;

    return (
      <div
        className={`accordion-content ${isOpen ? "open-accordion" : ""}`}
        style={{
          height: `${height}px`,
          overflow: "hidden",
          transition: "height 0.3s ease",
        }}
        ref={this.contentRef}>
        <ol>
          {data.map((item, index) => (
            <li
              key={index}
              dangerouslySetInnerHTML={{ __html: item.id }}
              style={{
                color:
                  item.isPositive === null
                    ? "black"
                    : item.isPositive
                    ? "green"
                    : "red",
              }}></li>
          ))}
        </ol>
      </div>
    );
  }
}

// Componente principale CustomCarousel
export default class CustomCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 0, // Indice della slide attiva
      openAccordionIndex: null, // Indice dell'accordion aperto
    };

    this.carouselRef = React.createRef(); // Per controllare i click fuori dal carosello
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      this.carouselRef.current &&
      !this.carouselRef.current.contains(event.target)
    ) {
      // Chiudi l'accordion se si clicca fuori dal carosello
      this.setState({ openAccordionIndex: null });
    }
  };

  toggleAccordion = (index) => {
    this.setState((prevState) => ({
      openAccordionIndex: prevState.openAccordionIndex === index ? null : index,
    }));
  };

  handleSlideChange = (index) => {
    this.setState({ active: index, openAccordionIndex: null }); // Chiudi l'accordion quando cambia slide
  };

  render() {
    const { openAccordionIndex } = this.state;
    const cards = this.props.cards;
    const { t } = i18next;

    console.log("Props ricevute:", this.props);

    const topEnablersData = (this.props.topEnablersMarkers || []).map(
      (marker) => ({
        id: marker,
        isPositive: null,
      })
    );

    const topBlockersData = (this.props.topBlockersMarkers || []).map(
      (marker) => ({
        id: marker,
        isPositive: null,
      })
    );

    const topGapMarkersData = Array.isArray(this.props.topGapMarkers)
      ? this.props.topGapMarkers.map((marker) => ({
          id: marker.behaviorMarkerTitle,
          // isPositive: marker.isPositive,
          isPositive: null,
        }))
      : [];

    if (
      topEnablersData.length === 0 ||
      topBlockersData.length === 0 ||
      topGapMarkersData.length === 0
    ) {
      console.warn(
        "Dati vuoti per enablersData o blockersData. Controlla l'API o il passaggio delle props."
      );
    }

    return (
      <div className="custom-carousel">
        <Carousel
          showThumbs={false}
          showArrows={false}
          showStatus={false}
          onChange={this.handleSlideChange}>
          {cards &&
            cards.map((card, idx) => {
              const isOpen = openAccordionIndex === idx; // Calcolo di isOpen
              return (
                <React.Fragment key={idx}>
                  {/* <div  className={`bg_img ${isOpen ? "open-accordion" : ""}`} key={idx}  style={{ backgroundImage: 'url(https://picsum.photos/800/500)' }} > */}

                  <div
                    className={`bg_img ${isOpen ? "open-accordion" : ""}`}
                    key={idx}
                    style={{
                      backgroundImage:
                        "url(/static-assets/slides/" +
                        card.backgroundImage +
                        ")",
                    }}>
                    <div className="topText">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: card.subtitle,
                        }}></span>{" "}
                      <b dangerouslySetInnerHTML={{ __html: card.title }}></b>
                    </div>
                    <div
                      className="bottomText"
                      dangerouslySetInnerHTML={{
                        __html: card.competencyName,
                      }}></div>
                    {(idx === 0 || idx === 1 || idx === 2) && (
                      <AccordionTitle
                        title={
                          idx === 0
                            ? t("topEnablersMarkers")
                            : idx === 1
                            ? t("topBlockersMarkers")
                            : t("topGapMarkers")
                        }
                        isOpen={isOpen}
                        toggleAccordion={() => this.toggleAccordion(idx)}
                      />
                    )}
                  </div>
                  {(idx === 0 || idx === 1 || idx === 2) && (
                    <AccordionContent
                      isOpen={isOpen}
                      data={
                        idx === 0
                          ? topEnablersData
                          : idx === 1
                          ? topBlockersData
                          : topGapMarkersData
                      }
                    />
                  )}
                </React.Fragment>
              );
            })}
        </Carousel>
      </div>
    );
  }
}
