import React, { Component } from 'react';
import { Button, Input } from 'reactstrap';
import ReactTags from 'react-tag-autocomplete'
import invitationService from 'api/invitationService';
import { throttle } from "lodash";
import {t} from "i18next";
import { stripHtml } from 'utils';

import {IconCross, IconSmsNotification} from 'components/icons'

const defaultAvatarUrl = '/img/profile-circle.svg'

export default class FollowUpSuggest extends Component {

    constructor(props) {
        super(props);

        this.onInputchange = this.onInputchange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);

        this.state = {
            tags: [],
            suggestions: [],
            busy: false,
            selectedMarker:this.props.defaultMarker,
            mailMessage:'',
            tagsKey: 0,
            emailError: '',
        }
    }

    componentDidMount() {


        invitationService.userSuggest(this.props.developmentLoopId).then(res => {

            const suggestions = res.data.map(u => {
                return { ...u, name:u.email, id: u.userId, avatarUrl: (u.avatarUrl || defaultAvatarUrl) }
            })

            this.setState({
                suggestions
            })

        })

    }

    onInputchange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    componentWillUnmount() {
    }

    onMarkerChanged(e) {
        console.log('onMarkerChanged', e.target.value)
        this.setState({
            selectedMarker: e.target.value,
        });
    }

    handleDelete(i) {
        const tags = this.state.tags.slice(0)
        tags.splice(i, 1)
        this.setState({ tags })
    }

    handleAddition(tag) {
      console.log('handleAddition', tag, typeof tag.id);

      let myFullTag;
      if (tag.id > 0) {
          myFullTag = this.state.suggestions.find(s => s.id === tag.id);
          console.log(myFullTag);
      } else {
          const emailRegExp = new RegExp('^(.+)@(.+)\\.(.+)$');
          if (!emailRegExp.test(tag.name)) {
              this.setState({ emailError: 'The email entered is invalid.' });
              return;
          }
          myFullTag = {
              email: tag.name,
          };
      }

      // Verifica se l'email è già presente nei tag
      const emailExists = this.state.tags.some(existingTag => existingTag.email === myFullTag.email);
      if (!emailExists) {
          const tags = [].concat(this.state.tags, myFullTag);
          this.setState({ tags, tagsKey: this.state.tagsKey + 1, emailError: '' }); // Incrementa la chiave per forzare il reset di ReactTags
      }
    }

    handleSuggestionsTransform(query, suggestions){
        const lowerCaseQuery = query.toLowerCase();
        return suggestions.filter(r => r.email.toLowerCase().indexOf(lowerCaseQuery) != -1 );
    }

    /*
    handleInputChange = throttle((query) => {
        if (query == ''){
            console.log('esco');
            return
        }

            invitationService.userSuggest(query).then(res => {

                const suggestions = res.data.map(u => {
                    //return { ...u, name:u.firstName + ' ' + u.lastName, id: u.userId, avatarUrl: (u.avatarUrl || defaultAvatarUrl) }
                    return { ...u, name:u.email, id: u.userId, avatarUrl: (u.avatarUrl || defaultAvatarUrl) }
                })

                this.setState({
                  //  busy: false,
                    suggestions
                })

            })

    },300)
    */

    handleBlur() {
      const emailInput = document.querySelector('.react-tags__search-input');

      if (emailInput && emailInput.value) {
          // Aggiungi l'email come tag
          this.handleAddition({ name: emailInput.value });

          // Svuota il valore dell'input per rimuoverlo visivamente
          emailInput.value = '';
      } else {
          // Se l'input è vuoto, resetta il messaggio di errore
          this.setState({ emailError: '' });
      }
    }


    render() {
        const { tags, suggestions, selectedMarker, tagsKey } = this.state
        const {markers} = this.props

        return (
            <div className='followUpSuggestWrapper'>
                <div className='followUpSuggestBlock'>

                <div className="followUpSuggestIntro">{t('devloop.followup.chooseMarker')}</div>
                <div className='custom_input_label' style={{display: 'flex', flexWrap: 'wrap'}}>
                {markers.map((item, idx) => (
                     <React.Fragment key={idx.toString()}>
                          <div className='custom_flex' style={{ display: 'flex', alignItems: 'center', gap: '4px'}}>
                            <input
                                type="radio"
                                name="selectedMarker"
                                id={'chk_' + item.behaviorMarkerId}
                                value={item.behaviorMarkerId}
                                checked={this.state.selectedMarker == item.behaviorMarkerId}
                                onChange={this.onMarkerChanged.bind(this)}
                            /> <label htmlFor={'chk_' + item.behaviorMarkerId}>{item.shortTitle}</label>
                          </div>
                    </React.Fragment>
                ))}
                </div>
                </div>

                <div>

                    <b className='text-primary'>Email</b>
                    <ReactTags
                        key={tagsKey}
                        tags={tags}
                        allowNew={true}
                        suggestionsTransform={this.handleSuggestionsTransform.bind(this)}
                        placeholderText=''
                        noSuggestionsText={'No suggestions found'}
                        suggestions={suggestions}
                        onDelete={this.handleDelete.bind(this)}
                        onBlur={this.handleBlur}
                        onAddition={this.handleAddition.bind(this)}
                        tagComponent={TagComponent} />

                    {this.state.emailError && (
                        <div className="error-message" style={{ color: 'red' }}>{this.state.emailError}</div>
                    )}

<div className="form-group my-3 pt-3">
                        <b className='text-primary'>{t('devloop.followup.lblEmailMessage')}</b>
                        <Input
                            name="mailMessage"
                            placeholder=""
                            type="textarea"
                            className='form-control main mb-1 mailMessage'
                            value={this.state.mailMessage}
                            rows="3"
                            onChange={this.onInputchange}
                        />
                    </div>

                    <Button  disabled={this.state.tags.length == 0} className='btn btn-primary w-100 btn-send' onClick={() => { this.props.onSend(tags,selectedMarker,stripHtml(this.state.mailMessage)) }}><IconSmsNotification nopadding={true} displayblock={true} />Send invite</Button>
                </div>

            </div>
        );
    }
}


export function TagComponent({ tag, removeButtonText, onDelete }) {
    return (


        <div className='contact-tag'>
            <div className="contact-tag-inner">
                <span className='tag-name'>{tag.email}</span>
                <span className='btn-remove-contact' onClick={onDelete}>
                    <IconCross nopadding={true} color="#ffa61e" />
                </span>
            </div>
        </div>


    )
}