export const ATRAIN_ROUTES = {
    LOGIN:'/login',
    FORGOT_PASSWORD:'/account/forgot-password',
    RESET_PASSWORD:'/account/password-reset',
    CLAIM_INVITATION:'/claiminvitation',
    SET_AVATAR:'/set-avatar/:isregistration?',
    REPORT:'/report/:assessmentId',
    DEVLOOP:'/devloop/:developmentLoopId',
    FOLLOWUP:'/devloop/followup/:developmentLoopId/:behaviorMarkerId',
    DASHBOARD:'/dashboard',
    TOP_BEHAVIORS:'/top-behaviors/:assessmentId/:toptype?',
    ASSESSMENT:'/assessment/:assessmentId',
    INVITE_RATER:'/invite-raters/:assessmentId',
    INVITE_SPONSOR:'/invite-sponsor/:assessmentId',
    INVITE_MORE_RATERS:'/invite-more-raters/:assessmentId',
    INVITE_RATER_DEVLOOP:'/invite-raters-devloop/:assessmentId/:toptype?',
    TEST_ROUTE:'/foo/:t/bar/:detailId?/prova/:cfg',
    RATER_FEEDBACK: '/rater-feedback/:assessmentId'
}

export const ATRAIN_UNAUTHENTICATED_ROUTES = [
    ATRAIN_ROUTES.CLAIM_INVITATION,
    ATRAIN_ROUTES.LOGIN,
    ATRAIN_ROUTES.FORGOT_PASSWORD,
    ATRAIN_ROUTES.RESET_PASSWORD
  ]

export function getRoute(){
    const paramsType = Object.prototype.toString.call(arguments[1])
    if (paramsType == '[object Object]' || paramsType == '[object Undefined]'){
        return getRouteObj(arguments[0], arguments[1])
    }else{
        return getRouteArgs(...arguments)
    }
}

//getRoute('/foo/:param1/param2?', 'val1', 'val2')
function getRouteArgs(){
    var routeRegexp = /:([^:\/]+)/gi;
    let route = arguments[0]
    const args = arguments
    let idx = 0
    route = route.replace(routeRegexp, function($0,$1){
        idx++
        return args[idx]
    });
    return route
}

//getRoute('/foo/:param1/param2?', {param1:'val1', param2:'val2'})
function getRouteObj(route, replacements){

    var routeRegexp = /:([^:\/]+)/gi;
    route = route.replace(routeRegexp, function($0,$1){
        /*if($1.replace('?','') == 'langcode'){
            return window.__appLang
        }else{*/
        return replacements && replacements[$1.replace('?','')]
        //}
    });
//    route = route.replace('//','/') // solo funzionale a poter sviluppare senza lingua, in attesa di gestione lato API


    return route
}